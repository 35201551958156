:root {
  --gap-2xs: 2px;
  --gap-xs: 4px;
  --gap-s: 8px;
  --gap-m: 16px;
  --gap-l: 24px;
  --gap-xl: 32px;
  --gap-2xl: 48px;
  --gap-3xl: 64px;
}
