.notice {
  animation: 1.5s linear 0s 1 animation-slide forwards;
}

@keyframes animation-slide {
  0% {
    opacity: 1;
    transform: translate(-50%, -6px);
  }
  50% {
    opacity: 1;
    transform: translate(-50%, -19px);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -32px);
  }
}
