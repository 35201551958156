.container {
  display: flex;
  align-items: center;
  gap: var(--gap-xl);
  background: var(--color-black-alpha50);
  border-radius: var(--radius-l);
  padding: 40px 80px 40px 50px;

  &.accent:not(:hover) {
    animation: 2s ease-in-out 0s infinite animation-blink;
  }

  &:not(.accent) {
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }

  &:hover {
    cursor: var(--cursor-pointer), auto;
    background: var(--color-brilliant-gradient);
  }
}

.icon {
  width: 54px;
  height: 54px;
}

@keyframes animation-blink {
  0% {
    background: var(--color-brilliant);
  }
  50% {
    background: var(--color-brilliant-dark);
  }
  100% {
    background: var(--color-brilliant);
  }
}
