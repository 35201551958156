.container {
  background: var(--color-black-alpha50);
  position: relative;
  height: 7px;
  width: 100%;
  border-radius: var(--radius-2xs);
  overflow: hidden;
  box-shadow: 0 1px 0 var(--color-black-alpha25);
}

.progress {
  position: absolute;
  background: var(--color-white);
  left: 0;
  top: 0;
  bottom: 0;
}
