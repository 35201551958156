.button {
  pointer-events: auto;
  position: absolute;
  background: var(--color-black);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  &:hover {
    cursor: var(--cursor-pointer), auto;
    background: var(--color-brilliant-dark);
  }

  &.active {
    background: var(--color-brilliant-alpha50);
  }
}

[data-device="desktop"] .button {
  width: 48px;
  height: 48px;
  right: -8px;
  top: -8px;
}

[data-device="mobile"] .button {
  width: 70px;
  height: 70px;
  right: -14px;
  top: -14px;
}

.icon {
  width: 40%;
  height: 40%;
}
