.wrapper {
  position: relative;
}

.value {
  display: flex;
  align-items: center;
  gap: var(--gap-s);
  border-radius: var(--radius-m);
  background: var(--color-white-alpha10);
  padding: 9px 14px 8px 14px;
  letter-spacing: 2px;

  &:hover,
  &.active {
    cursor: var(--cursor-pointer), auto;
    background: var(--color-white-alpha25);
  }
}

.options {
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translate(var(--gap-m), -50%);
  border-radius: var(--radius-m);
  overflow: hidden;
  // z-index: 100;
}

.icon {
  transform: translateY(-1px);
}
