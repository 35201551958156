.row {
  &:not(:first-child) {
    box-shadow: 0 1px 0 var(--color-white-alpha10) inset;
  }

  td {
    padding: var(--gap-m);

    &:last-child {
      padding-right: var(--gap-xl);
    }
  }
}

.info {
  display: flex;
  align-items: center;
  gap: var(--gap-m);
}

.place {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  border-radius: var(--radius-m);

  &.self {
    background-color: var(--color-brilliant-dark);
  }
}
