.wrapper {
  overflow: hidden;
  position: relative;
  flex: 1;
  margin-right: -24px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.track {
  overflow: hidden;
  width: 16px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--color-black-alpha25);
  border-radius: 6px;

  &:not(.visible) {
    display: none;
  }
}

.thumb {
  position: relative;
  width: 100%;

  .shape {
    background-color: var(--color-white-alpha25);
    position: absolute;
    inset: 3px;
    border-radius: 5px;

    &.dragging {
      background-color: var(--color-white-alpha75);
    }
  }

  &:hover .shape:not(.dragging) {
    background-color: var(--color-white-alpha50);
  }
}
