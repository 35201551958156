.button {
  pointer-events: auto;
  position: absolute;
  background: var(--color-black-alpha50);
  width: 160px;
  height: 160px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  bottom: 0;

  &:active {
    .icon {
      fill: var(--color-white);
    }
  }

  &.blink {
    animation: 2s ease-in-out 0s infinite animation-blink;
  }
}

.icon {
  fill: var(--color-white-alpha75);
  width: 50%;
  height: 50%;
}

@keyframes animation-blink {
  0% {
    background: var(--color-black-alpha50);
  }
  50% {
    background: var(--color-brilliant-alpha50);
  }
  100% {
    background: var(--color-black-alpha50);
  }
}
