.wrapper {
  flex: 1;
  position: relative;

  &:not(.disabled):hover {
    cursor: var(--cursor-pointer), auto;

    .container {
      background: var(--color-black);
    }

    &.type-break {
      .container {
        background: var(--color-negative);
      }
    }

    .hotkey {
      display: flex;
    }
  }

  &.disabled {
    .container {
      background: var(--color-black-alpha50);
    }

    .icon {
      opacity: 0.5;
    }
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: 11px 0;
  height: 56px;
  background: var(--color-black-alpha75);
  border-radius: var(--radius-m);
  overflow: hidden;

  &:not(:last-child) {
    border-radius: var(--radius-m) var(--radius-m) 0 0;
  }

  &.blink {
    animation: 1s ease-in-out 0s infinite animation-blink;
  }
}

.cost {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 7px 0 9px 0;
  border-radius: 0 0 var(--radius-m) var(--radius-m);
  background: var(--color-black-alpha50);
  position: absolute;

  &.less {
    background: var(--color-negative-alpha75);
  }
}

.hotkey {
  position: absolute;
  inset: 0;
  background: var(--color-black-alpha50);
  padding-top: 4px;
  justify-content: center;
  align-items: center;
  display: none;
}

@keyframes animation-blink {
  0% {
    background: var(--color-brilliant-dark-alpha75);
  }
  50% {
    background: var(--color-brilliant-alpha75);
  }
  100% {
    background: var(--color-brilliant-dark-alpha75);
  }
}
