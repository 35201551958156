* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
}

:root {
  --viewport-height: 100vh;
  --viewport-width: 100vw;
}

body {
  height: 100vh;
  width: 100vw;
  background: var(--color-main-dark);
  overflow: hidden;
  touch-action: none;
  user-select: none;
}

svg {
  flex-shrink: 0;
  fill: var(--color-white);
}

body,
input,
button,
a {
  color: var(--color-white);
  font-family: "Kelson";
}

a {
  text-decoration: none;
  position: relative;
  display: inline-block;

  &::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    border-bottom: 1px dashed #fff;
  }

  &:hover {
    cursor: var(--cursor-pointer), auto;

    &::after {
      border-bottom-style: solid;
    }
  }
}

img {
  display: block;
}

#game {
  position: fixed;
  inset: 0;

  @media (orientation: portrait) {
    transform: rotate(90deg);
    transform-origin: left top;
    left: 100%;
    width: var(--viewport-height);
    height: var(--viewport-width);
  }
}

#game-ui,
#game-loading {
  position: fixed;
  inset: 0;
  -webkit-touch-callout: none;
  pointer-events: none;
}

#game-ui {
  z-index: 9997;
}

#game-loading {
  background: var(--color-main-dark);
  z-index: 9998;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  pointer-events: all;

  &.hidding {
    animation: 0.5s linear 0.5s 1 animation-hide forwards;
  }

  &.hidden {
    display: none;
  }

  .logotype {
    width: 7vw;
    height: 7vw;
    margin-bottom: 5vh;
    animation: 1s ease-in-out 0s infinite animation-jump;
    position: relative;

    svg {
      width: 100%;
      height: 100%;
    }

    @media (orientation: portrait) {
      font-size: 7vh;
      margin-bottom: 5vw;
      width: 7vh;
      height: 7vh;
    }
  }

  .status {
    font-size: 1vw;
    line-height: 1vw;
    text-transform: uppercase;
    white-space: pre;
    text-align: center;
    height: 3vw;
    letter-spacing: 2px;
    font-weight: bold;

    @media (orientation: portrait) {
      font-size: 1.5vh;
      line-height: 1.5vh;
      margin-bottom: 3vh;
    }
  }
}

@keyframes animation-hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes animation-jump {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0);
  }
}
