.button {
  background: var(--color-black-alpha25);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: var(--cursor-pointer), auto;

    .icon {
      width: 70%;
    }
  }

  @media (max-height: 479px) {
    width: 80px;
    height: 80px;
  }
}

.icon {
  transition: width 0.1s linear;
  width: 60%;
}
