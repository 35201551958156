.button {
  pointer-events: auto;
  width: 46px;
  height: 46px;
  box-shadow: 0 0 0 6px var(--color-black-alpha25);
  border-radius: var(--radius-m);
  display: flex;
  align-items: center;
  justify-content: center;

  &.confirm {
    background: rgba(79, 190, 24, 0.75);

    &.disabled {
      background: var(--color-black);
      opacity: 0.25;
    }
  }

  &.decline {
    background: rgba(225, 49, 49, 0.75);
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  width: 160px;
}
