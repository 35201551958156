.container {
  background: var(--color-black-alpha50);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  pointer-events: auto;
}

[data-device="desktop"] .container {
  width: 64px;
  height: 64px;
  border-radius: var(--radius-s);

  &:hover {
    &.usable {
      cursor: var(--cursor-pointer), auto;
      background: var(--color-brilliant-alpha50);
    }

    > div {
      display: flex;
    }
  }
}

[data-device="mobile"] .container {
  width: 90px;
  height: 90px;
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
  right: 0px;
  bottom: 0px;

  &.passive {
    background: var(--color-black-alpha25);
  }
}

.icon {
  width: 32px;
  height: 32px;
  position: relative;
  z-index: 2;
}

.hotkey {
  position: absolute;
  top: -4px;
  right: -4px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--color-black);
  display: flex;
  justify-content: center;
  align-items: center;
}

.timeout {
  position: absolute;
  inset: 0;
  border-radius: var(--radius-s);
  background: conic-gradient(
    transparent 0deg var(--progress),
    var(--color-negative-alpha50) var(--progress) 360deg
  );
}
