:root {
  // ----- WHITE

  --color-white: rgb(255, 255, 255);
  --color-white-alpha10: rgba(255, 255, 255, 0.1);
  --color-white-alpha15: rgba(255, 255, 255, 0.15);
  --color-white-alpha25: rgba(255, 255, 255, 0.25);
  --color-white-alpha50: rgba(255, 255, 255, 0.5);
  --color-white-alpha75: rgba(255, 255, 255, 0.75);

  // ----- BLACK

  --color-black: rgb(0, 0, 0);
  --color-black-alpha10: rgba(0, 0, 0, 0.1);
  --color-black-alpha15: rgba(0, 0, 0, 0.15);
  --color-black-alpha25: rgba(0, 0, 0, 0.25);
  --color-black-alpha50: rgba(0, 0, 0, 0.5);
  --color-black-alpha75: rgba(0, 0, 0, 0.75);

  // ----- SELF

  --color-self: rgb(67, 163, 219);
  --color-self-alpha25: rgba(67, 163, 219, 0.25);

  --color-self-dark: hsl(202, 68%, 28%);
  --color-self-dark-alpha75: rgba(23, 84, 120, 0.75);

  // ----- OPPONENT

  --color-opponent: rgb(255, 155, 73);
  --color-opponent-alpha25: rgba(255, 155, 73, 0.25);

  --color-opponent-dark: rgb(143, 64, 0);
  --color-opponent-dark-alpha75: rgba(143, 64, 0, 0.75);

  // ----- INFO

  --color-info: rgb(150, 200, 255);

  // ----- BRILLIANT

  --color-brilliant: rgb(0, 130, 113);
  --color-brilliant-alpha15: rgba(0, 130, 113, 0.15);
  --color-brilliant-alpha25: rgba(0, 130, 113, 0.25);
  --color-brilliant-alpha50: rgba(0, 130, 113, 0.5);
  --color-brilliant-alpha75: rgba(0, 130, 113, 0.75);

  --color-brilliant-light: rgb(138, 223, 211);

  --color-brilliant-dark: rgb(5, 90, 79);
  --color-brilliant-dark-alpha75: rgba(5, 90, 79, 0.75);

  --color-brilliant-gradient: linear-gradient(
    160deg,
    var(--color-brilliant) 0%,
    var(--color-brilliant-dark) 100%
  );
  --color-brilliant-gradient-alpha75: linear-gradient(
    160deg,
    var(--color-brilliant-alpha75) 0%,
    var(--color-brilliant-dark-alpha75) 100%
  );

  // ----- AQUA

  --color-aqua: rgb(39, 89, 129);
  --color-aqua-alpha50: rgba(39, 89, 129, 0.5);
  --color-aqua-alpha75: rgba(39, 89, 129, 0.75);

  --color-aqua-light: rgb(31, 116, 186);

  --color-aqua-dark: rgb(26, 30, 54);
  --color-aqua-dark-alpha50: rgba(26, 30, 54, 0.5);
  --color-aqua-dark-alpha75: rgba(26, 30, 54, 0.75);

  --color-aqua-gradient-alpha75: linear-gradient(
    160deg,
    var(--color-aqua-alpha75) 0%,
    var(--color-aqua-dark-alpha75) 100%
  );

  // ----- NEGATIVE

  --color-negative: rgb(143, 24, 24);
  --color-negative-alpha25: rgba(143, 24, 24, 0.25);
  --color-negative-alpha50: rgba(143, 24, 24, 0.5);
  --color-negative-alpha75: rgba(143, 24, 24, 0.75);

  --color-negative-light: rgb(227, 77, 77);
  --color-negative-light-alpha25: rgba(227, 77, 77, 0.25);

  --color-negative-dark: rgb(80, 11, 11);
  --color-negative-dark-alpha25: rgba(80, 11, 11, 0.25);
  --color-negative-dark-alpha50: rgba(80, 11, 11, 0.5);
  --color-negative-dark-alpha75: rgba(80, 11, 11, 0.75);

  --color-negative-gradient-alpha75: linear-gradient(
    160deg,
    var(--color-negative-alpha75) 0%,
    var(--color-negative-dark-alpha75) 100%
  );

  // ----- MAIN

  --color-main: #2f3149;

  --color-main-dark: #202232;
  --color-main-dark-alpha85: rgba(32, 34, 50, 0.85);

  // ----- OTHER

  --color-accent: #68deff;
}
