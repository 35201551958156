.container {
  position: absolute;
  animation: 1s ease-out 0s 1 animation-slide forwards;
  bottom: 0;
  text-align: center;
}

@keyframes animation-slide {
  0% {
    opacity: 0;
    transform: translateY(16px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
