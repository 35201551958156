.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  gap: var(--gap-s);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: 0.5s ease-out 1s 1 animation-arise forwards;
}

.alert {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #f44242;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  animation: 1s ease-in-out 0s infinite animation-bounce;
}

.label {
  background: var(--color-black-alpha50);
  padding: 3px 5px 2px 5px;
  border-radius: var(--radius-xs);
}

@keyframes animation-bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes animation-arise {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
