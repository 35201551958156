.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  flex-shrink: 0;

  &:not(.view-transparent) {
    &.size-m {
      border-radius: var(--radius-m);
      padding: var(--gap-l) var(--gap-xl);
    }

    &.size-l {
      border-radius: var(--radius-l);
      padding: var(--gap-xl) var(--gap-2xl);
    }
  }

  &.size-m {
    gap: var(--gap-s);
  }

  &.size-l {
    gap: var(--gap-m);
  }

  &.view-default {
    background: var(--color-black-alpha25);
  }

  &.view-light {
    background: var(--color-black-alpha15);
  }
}

.label {
  opacity: 0.75;
}
