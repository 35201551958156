.noConnect {
  animation: 1s ease-in-out 0s infinite animation-blink;
  width: 24px;
  height: 24px;
}

@keyframes animation-blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
