.container {
  padding: var(--gap-l) var(--gap-xl);
  border-radius: var(--radius-l);
  background: var(--color-black-alpha25);
  display: flex;
  flex-direction: column;
  gap: var(--gap-m);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);

  &:hover {
    cursor: var(--cursor-pointer), auto;
    background: var(--color-black-alpha50);
  }
}

.icon {
  transform: translateY(-1px);
}
