.translator {
  position: fixed;
  transform: translate(-50%, -100%);
}

.wrapper {
  position: relative;
  min-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.head {
  position: absolute;
  min-width: 100%;
  bottom: 100%;
  transform: translateY(-16px);
}

.body {
  position: absolute;
  min-width: 100%;
  top: 50%;
  transform: translateY(-50%);
}
