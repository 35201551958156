.translator {
  position: absolute;
  z-index: 2;
  left: 50%;
  transform: translate(-50%, -100%);
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 6px;

  &.full {
    pointer-events: auto;
    transform: translateY(62px);
  }
}

.container {
  width: 180px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: var(--gap-m);
  border-radius: var(--radius-m);
  background: linear-gradient(
    0deg,
    var(--color-negative-alpha75) 0%,
    var(--color-negative-alpha50) 100%
  );

  &.arrow::after {
    position: absolute;
    content: "";
    border-style: solid;
    border-width: 14px 14px 0 14px;
    border-color: var(--color-negative-alpha75) transparent transparent
      transparent;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  &.selfOwn {
    background: linear-gradient(
      0deg,
      var(--color-aqua-dark-alpha75) 0%,
      var(--color-aqua-dark-alpha50) 100%
    );

    &.arrow::after {
      border-color: var(--color-aqua-dark-alpha75) transparent transparent
        transparent;
    }
  }
}
