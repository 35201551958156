.size {
  &.s {
    transform: scale(0.65);
  }

  &.m {
    transform: scale(0.8) translateX(-10px);
  }
}

.wrapper {
  height: 35px;
  display: flex;
  align-items: flex-end;
  transform: translateY(-5px);
}

.key {
  background: var(--color-white);
  border-bottom: 6px solid #999;
  border-radius: var(--radius-s);
  padding: 4px 12px 5px 12px;
  font-family: "Monofonto";
  color: var(--color-black);
  animation: 2s ease-in-out 0s infinite animation-press;
}

@keyframes animation-press {
  0% {
    border-bottom-width: 6px;
  }
  35% {
    border-bottom-width: 2px;
  }
  65% {
    border-bottom-width: 2px;
  }
  100% {
    border-bottom-width: 6px;
  }
}
