.screen {
  position: fixed;
  inset: 0;
  left: env(safe-area-inset-left, 0);
}

.front {
  position: relative;
  z-index: 3;
}

.buttons {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-m);
  width: 90px;
}

.grid {
  width: 100%;
  height: 100%;

  .column {
    position: absolute;
    top: var(--gap-xl);
    bottom: var(--gap-xl);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.left {
      left: var(--gap-xl);
      align-items: flex-start;
    }

    &.center {
      left: 0;
      right: 0;
      align-items: center;
    }

    &.right {
      right: var(--gap-xl);
      align-items: flex-end;
    }
  }
}
