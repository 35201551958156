.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  background: rgba(255, 119, 0, 0.75);
  background: linear-gradient(
    90deg,
    rgba(255, 119, 0, 0.75) 0%,
    rgba(255, 214, 0, 0.75) 100%
  );
  min-width: 200px;
  letter-spacing: 1px;
  padding: 9px 0;
  text-align: center;
  transform: rotate(-45deg);
  text-shadow: 1px 1px 0 var(--color-black-alpha25);
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-xs);
}
