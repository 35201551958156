.container {
  position: relative;
  border-radius: var(--radius-s);
  background: var(--color-white-alpha10);
  display: flex;
  align-items: center;
  gap: var(--gap-s);
  padding: var(--gap-m);
  border: 1px solid var(--color-white-alpha10);

  &.blink {
    animation: 1s ease-in-out 0s infinite animation-blink;
  }

  &.alarm {
    background: var(--color-negative-alpha25);
    border-color: var(--color-negative-light-alpha25);
  }

  &.locked {
    opacity: 0.5;
    filter: grayscale(1);
  }

  &.disabled {
    pointer-events: none;
  }

  &:hover {
    cursor: var(--cursor-pointer), auto;
    background: var(--color-brilliant-gradient-alpha75);
  }
}

@keyframes animation-blink {
  0% {
    background: var(--color-brilliant-alpha15);
  }
  50% {
    background: var(--color-brilliant-alpha50);
  }
  100% {
    background: var(--color-brilliant-alpha15);
  }
}
