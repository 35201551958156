.stages {
  display: flex;
  flex-direction: column-reverse;
  gap: 1px;
  border-radius: var(--radius-s);
  overflow: hidden;

  .stage {
    background: var(--color-brilliant-alpha25);
    width: 32px;
    height: 8px;

    &.active {
      background: var(--color-brilliant);
    }
  }
}

*:hover > .stages .stage.active + .stage:not(.active) {
  animation: 1s ease-in-out 0s infinite animation-blink;
}

.cost {
  display: flex;
  align-items: center;
  gap: 4px;
  height: 16px;

  &.less {
    color: var(--color-negative-light);
  }

  .amount {
    transform: translateY(-1px);
  }

  .postfix {
    opacity: 0.75;
  }
}

@keyframes animation-blink {
  0% {
    background: var(--color-brilliant);
  }
  50% {
    background: var(--color-brilliant-alpha25);
  }
  100% {
    background: var(--color-brilliant);
  }
}
