.container {
  border-radius: var(--radius-m);
  background: var(--color-negative-alpha75);
  padding: var(--gap-m) var(--gap-l);
  animation: 1s ease-in-out 0s infinite animation-blink;
  margin-bottom: var(--gap-l);
}

@keyframes animation-blink {
  0% {
    background: var(--color-negative-alpha75);
  }
  50% {
    background: var(--color-negative-dark-alpha50);
  }
  100% {
    background: var(--color-negative-alpha75);
  }
}
