@font-face {
  font-family: "Kelson";
  font-weight: 100;
  src: url("./Kelson-Light.woff2") format("woff2"),
    url("./Kelson-Light.woff") format("woff"),
    url("./Kelson-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Kelson";
  font-weight: 500;
  src: url("./Kelson-Normal.woff2") format("woff2"),
    url("./Kelson-Normal.woff") format("woff"),
    url("./Kelson-Normal.ttf") format("truetype");
}

@font-face {
  font-family: "Kelson";
  font-weight: 900;
  src: url("./Kelson-Bold.woff2") format("woff2"),
    url("./Kelson-Bold.woff") format("woff"),
    url("./Kelson-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Monofonto";
  src: url("./Monofonto.woff2") format("woff2"),
    url("./Monofonto.woff") format("woff"),
    url("./Monofonto.ttf") format("truetype");
}
