.text {
  white-space: pre;

  &.bold {
    font-weight: 900;
  }

  &.narrow {
    font-weight: 100;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.wrap {
    white-space: initial;
  }

  &.spacing {
    letter-spacing: 1px;
  }

  &.mono {
    font-family: "Monofonto";
  }

  &.view-secondary {
    color: var(--color-white-alpha50);
  }

  &.view-transparent {
    color: var(--color-white-alpha25);
  }

  &.view-dark {
    color: var(--color-black-alpha75);
  }

  &.view-brilliant {
    color: var(--color-brilliant-light);
  }

  &.view-primary {
    color: var(--color-info);
  }

  &.size-2xs {
    --font-size: 8pt;
  }

  &.size-xs {
    --font-size: 10pt;
  }

  &.size-s {
    --font-size: 12pt;
  }

  &.size-m {
    --font-size: 15pt;
  }

  &.size-l {
    --font-size: 17pt;
  }

  &.size-xl {
    --font-size: 20pt;
  }

  &.size-2xl {
    --font-size: 26pt;
  }

  &.size-3xl {
    --font-size: 34pt;
  }

  &.align-left {
    text-align: left;
  }

  &.align-center {
    text-align: center;
  }

  &.align-right {
    text-align: right;
  }

  font-size: var(--font-size);
  line-height: calc(var(--font-size) * 1.1);
}
