.warn {
  color: rgba(255, 255, 0, 0.7);
}

.alert {
  color: rgba(255, 0, 0, 0.7);
}

.normal {
  color: rgba(0, 255, 0, 0.7);
}
