.container {
  display: none;
  position: absolute;
  bottom: 100%;
  transform: translateY(-16px);
  width: 220px;
  background: var(--color-black-alpha50);
  padding: 16px;
  border-radius: var(--radius-s);

  &::after {
    position: absolute;
    content: "";
    border-style: solid;
    border-width: 14px 14px 0 14px;
    border-color: var(--color-black-alpha50) transparent transparent transparent;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.addon {
  opacity: 0.5;
}
