.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--gap-xl);

  &.up {
    .icon {
      animation: 1.5s ease-in-out 1s infinite animation-swipe-up;
    }

    .text {
      align-items: flex-start;
    }
  }

  &.left {
    flex-direction: column;
    margin-top: -30px;

    .icon {
      animation: 1.5s ease-in-out 1s infinite animation-swipe-left;
    }
  }
}

.icon {
  width: 100px;
  height: 100px;
}

.text {
  font-size: 22pt;
  line-height: 22pt;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-s);

  .large {
    font-size: 26pt;
    line-height: 26pt;
  }
}

@keyframes animation-swipe-up {
  0% {
    transform: translateY(40px);
  }
  100% {
    transform: translateY(-40px);
  }
}

@keyframes animation-swipe-left {
  0% {
    transform: translateX(50px);
  }
  100% {
    transform: translateX(-50px);
  }
}
