.parameters {
  display: flex;
  align-items: center;
  gap: var(--gap-l);
  pointer-events: auto;
  padding: 0 var(--gap-xl);
  background: var(--color-black-alpha50);
  height: 60px;
  border-radius: 30px;
}
