.wrapper {
  width: 100%;
  height: 100%;
  gap: var(--gap-l);
}

.leaders {
  display: flex;
  gap: var(--gap-m);
}

.table {
  background: var(--color-black-alpha25);
  border-radius: var(--radius-l);

  table {
    width: 100%;
    border-spacing: 0;
  }
}
