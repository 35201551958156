.grid {
  display: grid;
  gap: var(--gap-m);

  &.two {
    grid-template-columns: repeat(2, 1fr);
  }

  &.three {
    grid-template-columns: repeat(3, 1fr);
  }
}
