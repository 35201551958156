.wrapper {
  display: flex;

  &.vertical {
    flex-direction: column;
  }
  &.horizontal {
    flex-direction: row;
  }

  &.between {
    justify-content: space-between;
  }

  &.left {
    align-items: flex-start;
  }
  &.center {
    align-items: center;
  }
  &.right {
    align-items: flex-end;
  }

  &.stretch {
    width: 100%;
  }
}
