.checkbox {
  display: flex;
  align-items: center;
  gap: 12px;

  .marker {
    width: 24px;
    height: 24px;
    background: var(--color-black-alpha50);
    border-radius: var(--radius-s);
    border: 6px solid transparent;

    &.checked {
      border-color: var(--color-black);
      background: var(--color-brilliant);
    }

    &.invalid {
      animation: 1s linear 0s 1 animation-blink-error;
    }

    &:hover {
      cursor: var(--cursor-pointer), auto;

      &:not(.checked) {
        border-color: var(--color-black);
        background: var(--color-white);
      }
    }
  }
}

@keyframes animation-blink-error {
  0% {
    background: rgba(255, 50, 50, 0.5);
  }
  100% {
    background: var(--color-black-alpha50);
  }
}
