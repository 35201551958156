.icon {
  position: absolute;
  width: 24px;
  height: 24px;
  animation: 1s ease-in-out 0s infinite animation-jump;
  top: -32px;
}

@keyframes animation-jump {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(16px);
  }
  100% {
    transform: translateY(0);
  }
}
