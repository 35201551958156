.grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--gap-xl) var(--gap-2xl);
  background: var(--color-black-alpha25);
  padding: var(--gap-2xl);

  &:first-child {
    border-radius: var(--radius-xl) var(--radius-xl) 0 0;
  }

  &:last-child {
    background: var(--color-black-alpha10);
    border-radius: 0 0 var(--radius-xl) var(--radius-xl);
  }
}

.slot {
  opacity: 0.5;
  font-family: "Monofonto";
}
