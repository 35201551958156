.container {
  height: 7px;
  width: 100%;
  display: flex;
  gap: var(--gap-xs);
}

.stage {
  flex: 1;
  border-radius: var(--radius-2xs);
  height: 100%;
  background: var(--color-black-alpha50);

  &.active {
    background: var(--color-accent);
  }
}
