.label {
  animation: 0.4s linear 0s 1 animation-slide forwards;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -6px);
}

@keyframes animation-slide {
  0% {
    opacity: 1;
    transform: translate(-50%, -6px);
  }
  50% {
    opacity: 1;
    transform: translate(-50%, -19px);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -32px);
  }
}
