.container {
  padding: var(--gap-s) var(--gap-m);
  border-radius: var(--radius-m);
  background: linear-gradient(
    180deg,
    var(--color-black-alpha25) 0%,
    transparent 100%
  );
  transform: translateY(14px);
}
