.button {
  text-transform: uppercase;
  background: var(--color-aqua-gradient-alpha75);
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: var(--gap-m);
  transition: background-color 0.5s linear;
  padding: 22px 28px 20px 28px;
  border-radius: var(--radius-l);

  &:not(.disabled):hover {
    cursor: var(--cursor-pointer), auto;
    background-color: var(--color-aqua);
  }

  &.disabled {
    opacity: 0.75;
  }

  &.size-s {
    font-size: 14pt;
    line-height: 14pt;

    .icon {
      width: 18px;
      height: 18px;
    }
  }

  &.size-m {
    font-size: 17pt;
    line-height: 17pt;

    .icon {
      width: 24px;
      height: 24px;
    }
  }

  &.size-l {
    padding: 26px 36px 25px 36px;
    font-size: 19pt;
    line-height: 19pt;

    .icon {
      width: 28px;
      height: 28px;
    }
  }

  &.view-transparent {
    padding: 0;
    background: none;
    opacity: 0.75;

    &:not(.disabled):hover {
      opacity: 1;
      background: none;
    }
  }

  &.view-accent {
    background: var(--color-brilliant-gradient-alpha75);

    &:not(.disabled):hover {
      background-color: var(--color-brilliant);
    }
  }

  &.view-negative {
    background: var(--color-negative-gradient-alpha75);

    &:not(.disabled):hover {
      background-color: var(--color-negative);
    }
  }

  &.view-dark {
    background: var(--color-black-alpha25);

    &:not(.disabled):hover {
      background-color: var(--color-black-alpha75);
    }
  }
}

.icon {
  transform: translateY(-5%);
}
