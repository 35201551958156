.content {
  flex: 1;
  justify-content: center;
}

.frame {
  width: 124px;
  height: 124px;
  border-radius: var(--radius-l);
  background: var(--color-black-alpha50);
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    width: 50%;
    height: 50%;
  }
}

.meta {
  display: flex;
  padding: 4px;
  border: 2px solid transparent;
  position: relative;
  border-color: var(--color-black-alpha25);
  border-radius: 26px;
}
