.button {
  pointer-events: auto;
  background: var(--color-brilliant-alpha25);
  width: 64px;
  height: 64px;
  border-radius: var(--radius-l);
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: var(--cursor-pointer), auto;
    background: var(--color-brilliant-alpha50);

    .icon {
      transform: scale(1.2);
    }

    .hotkey {
      display: flex;
    }

    .content {
      display: none;
    }
  }

  &.active {
    background: var(--color-brilliant-alpha50);
  }
}

[data-device="mobile"] .button {
  width: 80px;
  height: 80px;
}

.icon {
  width: 50%;
  height: 50%;
  transition: transform 0.1s linear;
}

.hotkey {
  position: absolute;
  left: 100%;
  transform: translate(6px, 2px);
  display: none;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-xs);
}
