.button {
  border-radius: var(--radius-l);
  background: var(--color-white);
  min-width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.9;

  &:hover {
    cursor: var(--cursor-pointer), auto;
    opacity: 1;
  }
}

.icon {
  width: 28px;
  height: 28px;
}
