.wrapper {
  display: flex;
  align-items: center;
  gap: var(--gap-m);

  &.have {
    .frame {
      background: var(--color-aqua-alpha75);
    }
  }

  &.active {
    .frame {
      background: var(--color-brilliant);
    }

    .meta {
      border-color: var(--color-white-alpha25);
      border-radius: 26px;
    }
  }

  &:hover {
    cursor: var(--cursor-pointer), auto;

    &.have:not(.active) {
      .frame {
        background: var(--color-aqua);
      }
    }

    &:not(.have) {
      .frame {
        background: var(--color-black-alpha50);
      }
    }
  }
}

.meta {
  display: flex;
  padding: 4px;
  border: 2px solid transparent;
  position: relative;
}

.slot {
  background: var(--color-white-alpha75);
  border-radius: var(--radius-s);
  padding: 4px 6px 3px 6px;
}

.frame {
  width: 80px;
  height: 80px;
  border-radius: var(--radius-l);
  background: var(--color-black-alpha25);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  .icon {
    width: 45%;
    height: 45%;
  }
}

.commercialIcon {
  width: 18px;
  height: 18px;
  transform: translateY(-6%);
}
