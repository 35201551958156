.content {
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-s);
  border-left: 1px solid var(--color-white-alpha15);
  padding-left: var(--gap-m);
}
