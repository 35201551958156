@import "~core/client/ui/styles/utils/screen-height";
@import "~core/client/ui/styles/utils/screen-width";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: screenHeight(7%);
  background: var(--color-black-alpha10);
  border-radius: var(--radius-xl);
  padding: screenHeight(5%) screenWidth(5%);

  &.have {
    background: var(--color-black-alpha25);

    .frame {
      background: var(--color-aqua-alpha75);
    }
  }

  &.active {
    .frame {
      background: var(--color-brilliant);

      &::after {
        position: absolute;
        content: "";
        inset: -9px;
        border: 2px solid var(--color-white-alpha25);
        border-radius: 50%;
      }
    }
  }
}

.frame {
  position: relative;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background: var(--color-black-alpha25);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  @media (max-height: 479px) {
    width: 140px;
    height: 140px;
  }

  .icon {
    width: 45%;
    height: 45%;
  }
}

.description {
  height: 64px;
}
