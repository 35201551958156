.graphic {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-s);
  width: 50px;
  height: 50px;

  img {
    height: 100%;
  }
}
