.container {
  display: flex;
  gap: var(--gap-l);
  padding: 20px 50px 20px 25px;
  align-items: center;
  border-radius: var(--radius-l);

  @media (max-height: 479px) {
    gap: var(--gap-m);
    padding: 16px 40px 16px 22px;
  }

  &:hover {
    cursor: var(--cursor-pointer), auto;
    background: var(--color-black-alpha25);
  }

  &.primary {
    background: var(--color-brilliant-alpha50);

    &:hover {
      background: var(--color-brilliant);
    }
  }
}

.icon {
  @media (max-height: 479px) {
    width: 28px;
    height: 28px;
  }
}
