.wrapper {
  background: var(--color-black-alpha50);
  border-radius: var(--radius-xl);
  transform: translate(-16px, 16px);
  pointer-events: auto;

  canvas {
    transform: rotate(45deg);
    background: none;
    border-radius: var(--radius-xl);
  }
}
