.tab {
  padding: 8px 0 4px 0;
  border-bottom: 3px solid transparent;

  &.size-l {
    border-bottom-width: 4px;
  }

  &.active {
    border-color: var(--color-white);
  }

  &:not(.active) {
    opacity: 0.75;

    &:hover {
      cursor: var(--cursor-pointer), auto;
      opacity: 1;
    }
  }
}
