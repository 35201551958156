.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--gap-xl);
  position: relative;
  width: 320px;
  height: 320px;
}

.label {
  position: absolute;
  transform: translate(-50%, -35%);
  left: 50%;
  top: 50%;
}
