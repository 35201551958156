.wrapper {
  display: flex;
  align-items: center;
  gap: var(--space);

  &.xs {
    --icon-size: 16px;
    --text-size: 12pt;
    --space: var(--gap-s);
  }

  &.s {
    --icon-size: 18px;
    --text-size: 14pt;
    --space: var(--gap-s);
  }

  &.m {
    --icon-size: 24px;
    --text-size: 17pt;
    --space: 12px;
  }

  &.l {
    --icon-size: 34px;
    --text-size: 30pt;
    --space: var(--gap-m);
  }
}

.icon {
  width: auto;
  height: var(--icon-size);
}

.amount {
  font-family: "Monofonto";
  font-size: var(--text-size);
  line-height: var(--text-size);
  font-weight: bold;

  &.negative {
    color: var(--color-negative-light);
  }
}

.postfix {
  font-family: "Monofonto";
  font-size: calc(var(--text-size) * 0.6);
  line-height: calc(var(--text-size) * 0.6);
  opacity: 0.75;
  text-transform: uppercase;
}
