.preview {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-white-alpha10);
  border-radius: 50%;

  img {
    height: 85%;
  }
}
