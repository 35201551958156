.position {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  animation: 1s ease-out 0s 1 animation-slide forwards;
}

.wrapper {
  position: relative;
}

.body {
  position: absolute;
  padding: var(--gap-2xl) 100px;
  background: var(--color-black);
  border-radius: 0 0 100px 100px;
  inset: 0;
  opacity: 0.5;

  &:hover {
    cursor: var(--cursor-pointer), auto;
    opacity: 1;
  }

  &::after,
  &::before {
    position: absolute;
    content: "";
    width: 200px;
    height: 100px;
    background: var(--color-black);
    top: -28px;
  }

  &::before {
    transform: translateX(-50%) rotate(45deg);
    left: 0;
  }

  &::after {
    transform: translateX(50%) rotate(-45deg);
    right: 0;
  }
}

.container {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  gap: var(--gap-l);
  padding: var(--gap-2xl) 100px;
  pointer-events: none;
}

.frame {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  background: var(--color-white);
  border-radius: 50%;
}

.icon {
  width: 40%;
  height: 40%;
  fill: var(--color-black);
}

@keyframes animation-slide {
  0% {
    opacity: 0;
    top: -100%;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}
