.switch {
  position: relative;
  width: 64px;
  height: 38px;
  padding: 6px;
  border-radius: var(--radius-l);
  background: var(--color-negative-alpha75);
  transition: background-color 0.2s ease-out;

  &:hover {
    cursor: var(--cursor-pointer), auto;
    background: var(--color-negative);
  }

  &.active {
    background: var(--color-brilliant-alpha75);

    .thumb {
      transform: translateX(26px);
    }

    &:hover {
      background: var(--color-brilliant);
    }
  }

  .thumb {
    width: 26px;
    height: 26px;
    border-radius: var(--radius-l);
    background: var(--color-white);
    transition: transform 0.3s ease-out;
  }
}
