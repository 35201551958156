.close {
  transition: transform 0.2s ease-out;
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &:hover {
    cursor: var(--cursor-pointer), auto;
    transform: scale(1.25);
  }

  &.fixed {
    position: fixed;
    top: var(--gap-2xl);
    right: var(--gap-2xl);
  }

  svg {
    width: 150%;
    height: 150%;
  }
}
