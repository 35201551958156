.frame {
  width: 160px;
  height: 160px;
  transition: transform 0.2s ease-out;
  border-radius: var(--radius-l);
  background: var(--color-black-alpha25);
  gap: var(--gap-m);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: var(--cursor-pointer), auto;
    transform: scale(1.15);
  }
}

.lock {
  width: 32px;
  height: 32px;
}
