.indicators {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bars {
  display: flex;
  flex-direction: column;
  gap: var(--gap-2xs);
  width: 60px;
}
