@import "~core/client/ui/styles/utils/screen-height";
@import "~core/client/ui/styles/utils/screen-width";

.overlay {
  position: fixed;
  inset: 0;
  background: var(--color-main-dark-alpha85);
  z-index: 4;
  padding: screenHeight(8%) screenWidth(8%);
  display: flex;
  flex-direction: column;
  align-items: center;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.wrapper {
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  gap: screenHeight(3%);
  width: 100%;
  height: 100%;

  &.full {
    gap: 0;

    .header {
      position: absolute;
    }

    .content {
      height: 100%;
      align-items: center;
      justify-content: center;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  gap: var(--gap-2xl);
  height: 100px;
}

.bar {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--gap-xl);
}

.content {
  display: flex;
  width: 100%;
  height: calc(100% - 100px - screenHeight(3%)); // TODO: Refactor
}
