.wrapper {
  flex: 1;
  background-color: var(--color-black-alpha75);
  border-radius: var(--radius-l);
  position: relative;
  overflow: hidden;
  padding: var(--gap-2xl);

  &.place1 {
    --color-place: #e1a900;
  }

  &.place2 {
    --color-place: #8ea7aa;
  }

  &.place3 {
    --color-place: #c17447;
  }
}

.gradient {
  position: absolute;
  inset: 0;
  background: linear-gradient(90deg, var(--color-place) 0%, transparent 150%);
  opacity: 0.25;
}

.place {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-black-alpha25);
  border: 10px solid var(--color-place);
  position: relative;
  z-index: 2;
}

.info {
  position: relative;
  z-index: 2;
}

.values {
  border-top: 1px solid var(--color-white-alpha10);
  padding-top: 20px;
}
