.container {
  display: flex;
  align-items: center;
  pointer-events: auto;
  padding: 10px;
  background: var(--color-black-alpha50);
  height: 60px;
  border-radius: 30px;
  gap: var(--gap-m);
}

.frame {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  height: 40px;
  min-width: 40px;
}

.paused {
  background: linear-gradient(
    180deg,
    rgb(216, 148, 2) 0%,
    rgb(196, 101, 0) 100%
  );

  .icon {
    width: 20px;
    height: 20px;
  }
}

.number {
  padding: 0 var(--gap-m);
  background: linear-gradient(
    180deg,
    rgb(61, 147, 193) 0%,
    rgb(26, 91, 119) 100%
  );

  &.going {
    background: linear-gradient(
      180deg,
      rgb(193, 61, 61) 0%,
      rgb(127, 36, 36) 100%
    );
  }
}

.info {
  display: flex;
  align-items: center;
  gap: var(--gap-s);
  margin-right: var(--gap-m);
}

.skip {
  height: 40px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  background: var(--color-white-alpha10);
  padding: 0 14px;

  &.active:hover {
    cursor: var(--cursor-pointer), auto;
    background: var(--color-white-alpha25);
  }

  .icon {
    width: 26px;
    height: 26px;
  }
}
