.wrapper {
  position: relative;
}

.input {
  outline: none;
  padding: 25px 30px 25px 65px;
  font-size: 14pt;
  background: var(--color-black-alpha50);
  color: var(--color-white);
  border-radius: var(--radius-l);
  min-width: 340px;
  width: 100%;

  &:focus {
    background: var(--color-black-alpha75);
  }

  &::placeholder {
    color: var(--color-white-alpha25);
    opacity: 1;
  }

  &::-ms-input-placeholder {
    color: var(--color-white-alpha25);
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: var(--color-white) !important;
    -webkit-box-shadow: 0 0 0px 48px var(--color-black) inset !important;
  }

  &.invalid {
    animation: 1s linear 0s 1 animation-blink-error;
  }
}

.icon {
  position: absolute;
  width: 24px;
  height: 24px;
  fill: var(--color-white-alpha75);
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
}

@keyframes animation-blink-error {
  0% {
    background: rgba(255, 50, 50, 0.5);
  }
  100% {
    background: var(--color-black-alpha50);
  }
}
