.wrapper {
  position: relative;
  z-index: 0;
}

.base {
  width: 240px;
  height: 240px;
  border-radius: 50%;
  background: var(--color-black-alpha50);
  position: relative;
  pointer-events: auto;
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 0;
}

.stick {
  width: 50%;
  height: 50%;
  border-radius: 50%;
  background: var(--color-white-alpha50);
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: none;
  left: 0;
  top: 0;
  margin: 50% 0 0 50%;

  &.active {
    background: #fff;
  }
}

.deadzone {
  position: absolute;
  bottom: 120px;
  left: 120px;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  transform: translate(-50%, 50%);
  pointer-events: auto;
  z-index: 1;
}
