.error {
  background: var(--color-negative-alpha50);
  border-radius: var(--radius-l);

  &.size-s {
    padding: var(--gap-m);
    border-radius: var(--radius-m);
  }

  &.size-m {
    padding: 20px 25px;
    border-radius: var(--radius-l);
  }

  &.animate {
    animation: 0.15s linear 0s 3 animation-shake;
  }
}

@keyframes animation-shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}
