.screen {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
}

.shadow {
  position: fixed;
  inset: 0;
  box-shadow: 0 0 15vw var(--color-black-alpha50) inset;
  pointer-events: none;
}

.wrapper {
  width: 60%;
  height: 70%;
  display: flex;
  gap: 8%;

  @media (max-width: 1919px) {
    width: 70%;
  }

  @media (max-width: 1365px) {
    width: 80%;
  }

  @media (max-height: 479px) {
    height: 80%;
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 32px;
}

.content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
