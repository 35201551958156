.logotype {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 38pt;
  letter-spacing: 6px;
  transform: translate(2px, -6px);
}

.icon {
  width: 52px;
  height: 52px;
  transform: translate(-2px, -2px);
  fill: #4ae49d;
}
