.id {
  user-select: text;
}

.container {
  padding: 70px 80px;
  background: var(--color-black-alpha25);
  border-radius: var(--radius-l);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

.form {
  display: flex;
  align-items: center;

  input {
    user-select: text;
    font-size: 20pt;
    line-height: 20pt;
    width: 260px;
    text-transform: uppercase;
    background: var(--color-black-alpha50);
    border-radius: var(--radius-xl);
    padding: 20px 20px 20px 32px;

    &:focus {
      background: var(--color-black-alpha75);
    }

    &::placeholder {
      color: var(--color-white-alpha25);
      opacity: 1;
    }

    &::-ms-input-placeholder {
      color: var(--color-white-alpha25);
    }
  }

  &.error {
    input {
      animation: 0.45s linear 0s 1 animation-blink-error,
        0.15s linear 0s 3 animation-shake;
    }
    .button {
      animation: 0.15s linear 0s 3 animation-shake;
    }
  }

  .button {
    width: 44px;
    height: 44px;
    transition: transform 0.1s linear;
    background: var(--color-white);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -60px;

    &:hover {
      cursor: var(--cursor-pointer), auto;
      transform: scale(1.2);
    }
  }
}

@keyframes animation-blink-error {
  0% {
    background: transparent;
  }
  50% {
    background: rgba(255, 50, 50, 0.5);
  }
  100% {
    background: transparent;
  }
}

@keyframes animation-shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}
