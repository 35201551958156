.button {
  background: var(--color-black-alpha25);
  border-radius: var(--radius-m);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;

  &:hover {
    cursor: var(--cursor-pointer), auto;
    background: var(--color-black-alpha50);
  }
}

.text {
  transform: translateY(6%);
}

.input {
  padding: var(--gap-s) var(--gap-m);
  border-radius: var(--radius-m);
  height: 100%;
}
