.text {
  position: fixed;
  left: 50%;
  top: 32px;
  transform: translateX(-50%);
  animation: 3s ease-in-out 0s 1 animation-slide forwards;
}

@keyframes animation-slide {
  0% {
    opacity: 0;
    top: 32px;
  }
  15% {
    opacity: 1;
    top: 128px;
  }
  85% {
    opacity: 1;
    top: 128px;
  }
  100% {
    opacity: 0;
    top: 128px;
  }
}
