.screen {
  --fill: rgba(25, 0, 0, 0.5);

  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: 1s ease-in 0s 1 animation-fill forwards;
  pointer-events: auto;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);

  &.winner {
    --fill: rgba(0, 25, 0, 0.5);
  }
}

.offset {
  margin-top: 100px;
}

.title {
  text-transform: uppercase;
  font-size: 62pt;
  line-height: 62pt;
  font-weight: bold;
}

.block {
  background: var(--color-black-alpha25);
  padding: var(--gap-l) var(--gap-xl);
  border-radius: var(--radius-m);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-m);
}

@keyframes animation-fill {
  0% {
    background: transparent;
  }
  100% {
    background: var(--fill);
  }
}
