.text {
  font-size: 16pt;
  line-height: 20pt;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  white-space: pre;
}

[data-device="mobile"] .text {
  text-align: center;
  font-size: 20pt;
  line-height: 24pt;
}
