.avatar {
  width: 200px;
  height: 200px;
  background: var(--color-white-alpha10);
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}
