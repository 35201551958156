.bars {
  height: 16px;
  display: flex;
  flex-direction: column;
  gap: var(--gap-2xs);
}

.name {
  text-shadow: 1px 1px 0 var(--color-black-alpha25);
}
