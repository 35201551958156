.value {
  background: var(--color-white-alpha10);
  padding: 9px 14px 8px 14px;
  letter-spacing: 2px;

  &.active {
    background: var(--color-brilliant-alpha50);
  }

  &:not(.active):hover {
    cursor: var(--cursor-pointer), auto;
    background: var(--color-white-alpha25);
  }
}
