.container {
  display: flex;
  align-items: center;
  gap: var(--gap-m);
  background: var(--color-brilliant-dark-alpha75);
  padding: var(--gap-m) var(--gap-l);
  border-radius: var(--radius-m);

  &:hover {
    cursor: var(--cursor-pointer), auto;
    background: var(--color-brilliant-dark);
  }
}

.icon {
  width: 24px;
  height: 24px;
}
