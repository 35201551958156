.container {
  display: flex;
  align-items: center;
  gap: var(--gap-m);
  background: var(--color-black-alpha50);
  padding-right: 40px;
  border-radius: var(--radius-xl);
  margin-top: 10px;
}

.level {
  margin: -10px 0;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.level::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: conic-gradient(#5cbbe8 var(--progress), #303b4c 0);
  mask: radial-gradient(white 53%, transparent 0);
  mask-mode: alpha;
  -webkit-mask: radial-gradient(#0000 53%, #000 0);
  -webkit-mask-mode: alpha;
}
