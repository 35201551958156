.frame {
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-brilliant-gradient);
  border-radius: var(--radius-m);
  margin-left: -8px;
}

.icon {
  width: 36px;
  height: 36px;
}
