.effect {
  position: fixed;
  inset: 0;
  box-shadow: 0 0 256px #c80000 inset;
  opacity: 0.5;
  z-index: 2;

  &.beg {
    animation: 0.5s ease-in 0s 1 animation-blink-beg;
  }

  &.end {
    animation: 0.5s ease-out 0s 1 animation-blink-end;
  }
}

@keyframes animation-blink-beg {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes animation-blink-end {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}
