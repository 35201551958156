.wrapper {
  position: fixed;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  top: 50%;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

[data-device="mobile"] .wrapper {
  animation: 0.5s ease-out 0.5s 1 animation-arise forwards;
}

[data-device="desktop"] .wrapper {
  animation: 0.5s ease-out 0.5s 1 animation-arise-and-slide forwards;
}

[data-device="desktop"] .container {
  padding: var(--gap-l) var(--gap-2xl) var(--gap-l) var(--gap-xl);
  background: var(--color-black-alpha75);
  border-radius: var(--radius-l);
}

@keyframes animation-arise {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes animation-arise-and-slide {
  0% {
    transform: translate(-50%, 10%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}
