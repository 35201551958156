.overlay {
  position: fixed;
  z-index: 2;
  inset: 0;
  display: none;
  background: var(--color-black-alpha75);
  align-items: center;
  justify-content: center;
  pointer-events: auto;

  &.blur {
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }

  &.visible {
    display: flex;
  }
}
