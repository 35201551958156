.frame {
  width: 160px;
  height: 160px;
  border-radius: var(--radius-l);
  background: var(--color-black-alpha50);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s ease-out;

  &:hover {
    cursor: var(--cursor-pointer), auto;
    transform: scale(1.15);
  }
}

.icon {
  width: 40%;
  height: 40%;
}

.dot {
  width: 8%;
  height: 8%;
  border-radius: 50%;
  background: var(--color-white-alpha25);
}
